import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { Wrapper, Name, Subtitle, LinkButton } from "./styles"

import type { AmazonProduct as AmazonProductType } from "../../types/AmazonProduct"
import AmazonIcon from "../../svgs/AmazonIcon"

const AmazonProduct: React.FC<AmazonProductType & { className?: string }> = ({
  photo,
  name,
  subtitle,
  amazonLink,
  className,
}) => {
  return (
    <Wrapper to={amazonLink} target="_blank" className={className}>
      <GatsbyImage
        image={photo.gatsbyImageData}
        alt={photo.alt || name}
        imgStyle={{ objectFit: `contain` }}
      />
      <Name>{name}</Name>
      <Subtitle>{subtitle}</Subtitle>
      <LinkButton variant="primary">
        <AmazonIcon />

        <span>Buy now</span>
      </LinkButton>
    </Wrapper>
  )
}

export default AmazonProduct
